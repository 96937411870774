<template>
  <div>
    <div class="week_bg" style="background-image: url(img/athleticsRank/w_bg.png);">
      <div class="week_date_title">{{ actDate }}</div>
      <div class="week_back" style="background-image: url(img/athleticsRank/w_back.png);" @click="goBack"></div>
    </div>
    <div class="w_bg_other" style="background-image: url(img/athleticsRank/w_bg_other.png);">
      <div class="week_awards_title" style="background-image: url(img/athleticsRank/week_awards_title.png);"></div>
      <div class="week_awards" style="background-image: url(img/athleticsRank/week_awards.png);"></div>
      <div class="week_rule_title" style="background-image: url(img/athleticsRank/week_rule_title.png);"></div>
      <div class="week_rule" style="background-image: url(img/athleticsRank/week_rule.png);"></div>
      <div class="week_beself">本活动最终解释权归多乐所有</div>
    </div>

  </div>
</template>
  
<script>
import "@/assets/css/base.css"
import "@/assets/css/athletics.css"

export default {
  name: 'weekStarRule',
  data() {
    return {
      actDate: ""
    }
  },
  created() {
    this.actDate = this.$route.query.date
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  